import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Careers from "./pages/Careers/Careers";
import Contacts from "./pages/Contacts/Contacts";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Events from "./pages/Events/Events";
import PreHospital from "./pages/PreHospital/PreHospital";
import Transfer from "./pages/Transfer/Transfer";
import Privacy from "./pages/Privacy";
import TermsAndCond from "./pages/TermsAndCond";
import UCC from "./pages/UCC/UCC";
import useScrollToTop from "./hooks/useScrollToTop";
import CookiesPopup from "./pages/CookiesPopup";
import ErrorPage from "./pages/ErrorPage";
import OpsManager from "./pages/OpsManagerPost";
import Paramedics from "./pages/paramedicsPost";

export default function App() {
  useScrollToTop();
  return (
    <>
      <CookiesPopup />
      <Header />
      <CookiesPopup />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services/event-medical-cover" element={<Events />} />
        <Route path="/services/pre-hospital-care" element={<PreHospital />} />
        <Route
          path="/services/private-ambulance-transfer"
          element={<Transfer />}
        />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/job-post/opsmanager" element={<OpsManager />} />
        <Route
          path="/careers/job-post/paramedics-luton"
          element={<Paramedics />}
        />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<TermsAndCond />} />
        <Route path="/urgent-care-centre" element={<UCC />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </>
  );
}
