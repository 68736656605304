import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { AdvancedImage } from "@cloudinary/react";
import { getCloudinaryImage } from "../../utils/cloudinaryUtils";

export default function Hero() {
  const [isLoading, setIsLoading] = useState(true);
  const img = getCloudinaryImage("hero-image-home_phfunx");
  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  return (
    <div
      className={`relative isolate px-6 pt-18 lg:px-8  ${
        isLoading ? "bg-sky-950" : ""
      }`}
    >
      {/* <img
        src="/images/hero-image-home.jpg"
        alt="Ambulance staff transporting a patient inside the ambulance"
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        onLoad={handleImageLoaded}
        loading="lazy"
      /> */}
      <AdvancedImage
        cldImg={img}
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        onLoad={handleImageLoaded}
        alt="Ambulance staff transporting a patient inside the ambulance"
      />
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-sky-900 to-sky-700 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem] "
        />
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="hidden sm:mb-6 sm:flex sm:justify-center">
          <span className="relative text-xl font-bold leading-6 text-white ">
            Medical Emergency Solutions
          </span>
        </div>
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Experts in Ambulance Transfer and Pre-Hospital Care
          </h1>
          <p className="mt-6 text-lg md:text-xl leading-8 text-white ">
            Staffed by{" "}
            <strong>highly trained doctors, paramedics, and technicians</strong>
            , we ensure the highest quality of service for every situation.
          </p>
          <p className="mt-6 text-lg md:text-xl leading-8 md:leading-9 text-white ">
            Whether you require{" "}
            <strong>first aid event cover or a private ambulance,</strong> our
            medical teams ensure that on-site medical care is provided
            efficiently. We specialise in event medical support with{" "}
            <strong>rapid response teams</strong>, ensuring that all emergency
            situations are handled with the utmost professionalism.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              smooth
              to="#services-section"
              className="rounded-md bg-sky-800 px-3.5 py-2.5 text-md  font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Our services
            </Link>
            <Link
              to="/contacts"
              className="text-md font-semibold leading-6 text-white hover:text-teal-600"
            >
              Contact us <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#0A6CB5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
}
