import { CheckIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
const posts = [
  // {
  //   id: 3,
  //   title: "Operations and Events Manager",

  //   link: "/careers/job-post/opsmanager",
  //   description:
  //     "Join our team as an Operations and Events Manager, overseeing logistics and coordinating successful events for our ambulance services",
  //   requirements: [
  //     "Two to three years of experience in healthcare event management or a related field.",
  //     "A track record of ensuring healthcare regulatory compliance, such as Care Quality Commission (CQC).",
  //     "Strong risk assessment and contingency planning skills for medical emergencies.",
  //     "Proficiency in Microsoft Office (Word, Excel, PowerPoint) for administrative tasks and reporting.",
  //     "Collaborative leadership and excellent communication skills.",
  //     "Start date: ASAP",
  //   ],
  //   date: "Nov 21, 2024",
  //   datetime: "2024-11-21",
  //   category: { title: "Luton", urgent: "Urgent" },
  //   job: {
  //     type: "Full time",
  //     rate: "£33,280–£41,600 per year",
  //   },
  // },
  {
    id: 5,
    title: "Paramedics (Frontline) ",
    link: "/careers/job-post/paramedics-luton",
    description:
      "Qualified Paramedic for frontline work. Full-time Night Shifts 4on/4off",
    requirements: [
      "HCPC registration",
      "Minimum of 12 months experience in NHS trusts, working frontline post qualification.",
      "Up to date with all Immunisations.",
      "Enhanced DBS.",
      "At least 2 years driving experience with a full UK license (max 3 endorsement points)",
      "Experience in emergency and non-emergency driving.",
      "Ability to work in an Emergency and Urgent Care ambulance environment.",
      "Evidence of Continual Professional Development.",
      "Start date: ASAP",
    ],
    date: "Oct 26, 2024",
    datetime: "2024-10-26",
    category: { title: "Luton", urgent: "Urgent" },
    job: {
      type: "Full time",
      rate: "£60,000.00 per year",
    },
  },
  // {
  //   id: 4,
  //   title: "EMTs/AAPs (Frontline) ",
  //   href: "https://www.linkedin.com/jobs/view/4074787670/",
  //   description:
  //     "Qualified Emergency Medical Technician or Associate Ambulance Practitioner (IHCD/AAP). Full-time Night Shifts 4on/4off",
  //   requirements: [
  //     "IHCD Ambulance Technician BTEC level 3 or FREC 5",
  //     "Associate Ambulance Practitioner Level 4",
  //     "IHCD Emergency driver training or Futurequals Level 3 CERAD certificate in emergency response ambulance driving.",
  //     "A minimum of one-year of experience in frontline or urgent care.",
  //     "Up to date with all Immunisations.",
  //     "Enhanced DBS.",
  //     "Experience in emergency and non-emergency driving.",
  //     "Start date: ASAP",
  //   ],
  //   date: "Oct 26, 2024",
  //   datetime: "2024-10-26",
  //   category: { title: "Luton", urgent: "Urgent" },
  //   job: {
  //     type: "Full time",
  //     rate: "£45,500.00 per year",
  //   },
  // },

  {
    id: 2,
    title: "Paramedics - Night Shift 4on/4off",
    href: "mailto:recruitment@medicalemergencysolutions.co.uk",
    description:
      "Qualified Paramedic for Emergency 999 work. Monday to Friday with weekend availability",
    requirements: [
      "HCPC registration",
      "Minimum of 12 months experience in NHS trusts, working frontline post qualification.",
      "Up to date with all Immunisations.",
      "Enhanced DBS.",
      "At least 2 years driving experience with a full UK license (max 3 endorsement points)",
      "Experience in emergency and non-emergency driving.",
      "Ability to work in an Emergency and Urgent Care ambulance environment.",
    ],
    date: "Jun 26, 2024",
    datetime: "2024-07-26",
    category: { title: "Luton", href: "#" },
    job: {
      type: "Bank",
      rate: "£37/hour",
    },
  },
  // {
  //   id: 1,ç
  //   title: "Emergency Medical Technician (Frontline) - Night Shift 4on/4off",
  //   href: "mailto:recruitment@medicalemergencysolutions.co.uk",
  //   description:
  //     "Qualified Emergency Medical Technician or Associate Ambulance Practitioner (IHCD/AAP). Monday to Friday with weekend availability",
  //   requirements: [
  //     "IHCD Ambulance Technician BTEC level 3 or FREC 5",
  //     "Associate Ambulance Practitioner Level 4",
  //     "IHCD Emergency driver training or Futurequals Level 3 CERAD certificate in emergency response ambulance driving.",
  //     "A minimum of one-year of experience in frontline or urgent care.",
  //     "Up to date with all Immunisations.",
  //     "Enhanced DBS.",
  //     "Experience in emergency and non-emergency driving.",
  //   ],
  //   date: "Jul 31, 2024",
  //   datetime: "2024-07-31",
  //   category: { title: "Luton" },
  //   job: {
  //     type: "Contract (6 months)",
  //     rate: "£28/hour",
  //   },
  // },

  // More posts...
];

export default function CareerOffers() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-5xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Joining MES
          </h2>
          <p className="mt-10 text-lg leading-8 text-gray-600">
            When you join our team, you will participate in{" "}
            <strong>mandatory training </strong>
            programs to ensure your skills are always up-to-date. This includes
            training in safeguarding, moving and handling, and infection
            prevention and control.
          </p>
          <p className="mt-5 text-lg leading-8 text-gray-600">
            We value qualities such as{" "}
            <strong>
              flexibility, approachability, and the ability to remain calm under
              pressure
            </strong>
            . Our ideal staff members are enthusiastic, honest, and possess a
            high level of integrity. Demonstrating a caring attitude and empathy
            towards patients is essential.
          </p>
          <p className="mt-5 text-lg leading-8 text-gray-600">
            <strong>
              Excellent communication skills and proficiency in English
            </strong>{" "}
            are also important to succeed in our team.
          </p>
          <p className="mt-10 text-lg font-semibold leading-8 text-sky-800">
            See below our current offers:
          </p>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-12 sm:pt-12 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="flex max-w-xl flex-col items-start justify-between px-10 border-x border-gray-200"
            >
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time>
                <p className="relative z-10 rounded-full bg-sky-50 px-3 py-1.5 font-medium text-sky-900 ">
                  {post.category.title}
                </p>
                {post.category.urgent && (
                  <p className="relative z-10 rounded-full bg-red-500 px-3 py-1.5 font-medium text-white ">
                    {post.category.urgent}
                  </p>
                )}
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  {post.title}
                </h3>
                <p className="mt-5  text-sm leading-6 text-gray-600">
                  {post.description}
                </p>
                <ul>
                  {post.requirements.map((requirement) => (
                    <li
                      key={requirement}
                      className="mt-1 line-clamp-3 text-sm leading-6 text-gray-600"
                    >
                      <CheckIcon className="h-3 w-3 inline mx-2 text-sky-600" />
                      {requirement}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative mt-8 flex flex-col    gap-x-4 gap-y-4 w-full">
                <div className="text-sm ">
                  <p className="italic text-gray-600">{post.job.type}</p>
                  <p className="italic text-gray-600">Rate: {post.job.rate}</p>
                </div>
                <div className="flex justify-end ">
                  {post.href && (
                    <a
                      href={post.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative z-10 rounded-full bg-sky-50 px-3 py-1.5 font-medium text-sky-900 hover:text-white hover:bg-sky-600"
                    >
                      Apply now →
                    </a>
                  )}
                  {post.link && (
                    <Link to={post.link}>
                      <span className="relative z-10 rounded-full bg-sky-50 px-3 py-1.5 font-medium text-sky-900 hover:text-white hover:bg-sky-600">
                        Learn more →
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
