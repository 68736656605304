import ContactForm from "./ContactForm";
import ContactHero from "./ContactHero";
import MESLocation from "./MESLocation";
import SeparatorRight from "../../components/SeparatorRight";

import MESUCCLocation from "./MESUCCLocation";
import { Helmet } from "react-helmet";

export default function Contacts() {
  return (
    <>
      <Helmet>
        <title>Where We Are and How to Contact MES - Luton, London</title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/contacts"
        />
        <meta
          name="description"
          content="With Private ambulances London and Luton, MES provides services across the UK. Contact us today to discover how we can assist you with your medical needs."
        />
      </Helmet>
      <ContactHero />
      <SeparatorRight />
      <MESLocation />
      <MESUCCLocation />
      <ContactForm />
    </>
  );
}
