import CTA from "../../components/CTA";
import Clients from "../../components/Clients";
import SeparatorLeft from "../../components/SeparatorLeft";
import WorkWithUs from "../../components/WorkWithUs";
import PreHospitalContent from "./PreHospitalContent";
import PreHospitalHero from "./PreHospitalHero";
import SeparatorRight from "../../components/SeparatorRight";
import PreHospitalEthos from "./PreHospitalEthos";
import Staff from "../../components/Staff";
import Technology from "../../components/Technology";
import Compliance from "../../components/Compliance";
import { Helmet } from "react-helmet";

export default function PreHospital() {
  return (
    <div>
      <Helmet>
        <title>Pre-Hospital Care, First Aid and Medical Services on-site</title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/services/pre-hospital-care"
        />
        <meta
          name="description"
          content="With years of NHS frontline emergency experience, our team ensures the patients receive immediate and expert attention from the moment they arrive at the scene."
        />
      </Helmet>
      <PreHospitalHero />
      <SeparatorRight />
      <PreHospitalContent />
      <Technology />
      <PreHospitalEthos />
      <SeparatorLeft />
      <Staff />
      <CTA />
      <Compliance />
      <Clients />
      <WorkWithUs />
    </div>
  );
}
