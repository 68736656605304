import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Paramedics() {
  const responsibilities = [
    {
      name: "Emergency Response",
      list: [
        "Respond promptly and efficiently to emergency calls.",
        "Drive the ambulance safely, following road safety protocols to ensure the crew, patients, and public are protected.",
      ],
    },
    {
      name: "Patient Care",
      list: [
        "Conduct thorough assessments of patients' conditions.",
        "Deliver high-quality care, including Advanced Life Support (ALS) and Immediate Life Support (ILS) as required.",
        "Work collaboratively with EMTs and AAPs in providing emergency medical services.",
      ],
    },
    {
      name: "Transport and Transfer",
      list: [
        "Safely lift, move, and transport patients using appropriate equipment..",
        "Ensure the safe and comfortable transport of patients to healthcare facilities.",
        "Provide detailed handovers of patient conditions to receiving medical staff.",
      ],
    },
    {
      name: "Equipment and Ambulance Maintenance",
      list: [
        "Ensure the ambulance and its equipment are cleaned, stocked, and maintained after each call.",
        "Conduct regular checks on medical supplies and equipment.",
      ],
    },
    {
      name: "Record Keeping",
      list: [
        "Accurately document patient information, treatment provided, and observations in line with protocols.",
        "Complete incident reports and necessary paperwork efficiently.",
      ],
    },
    {
      name: "Training and Development",
      list: [
        "Participate in ongoing training and professional development programs.",
        "Complete mandatory training in Safeguarding, Moving and Handling, and Infection Prevention Control.",
        "Stay updated on the latest medical guidelines and procedures.",
      ],
    },
    {
      name: "Compliance and Safety",
      list: [
        "Adhere to legal, health, and safety regulations.",

        "Follow infection control protocols and the correct use of PPE.",
      ],
    },
  ];
  const qualifications = [
    {
      name: "Essential",
      list: [
        "Must hold a current HCPC registration.",
        "Minimum of 12 months experience working frontline in an NHS Trust post-qualification.",
        "Demonstrate ability to work in an emergency and urgent care ambulance environment.",
        "Strong communication and interpersonal skills",
        "Evidence of Continual Professional Development.",
        "Minimum of two years of driving experience with a full UK driving license and no more than 3 endorsement points.",
        "Experience in emergency and non-emergency driving.",
        "Up to date with all immunisations.",
        "Enhanced DBS check.",
        "Excellent communication skills and a good level of English.",
      ],
    },
    {
      name: "Desirable",
      list: [
        "Additional certifications in trauma care, advanced life support, or other specialised fields.",
        "Previous experience in emergency medical services.",
      ],
    },
    {
      name: "Physical Requirements",
      list: [
        "Ability to lift and transport patients and equipment.",
        "Stamina to work long shifts, including nights, weekends, and holidays.",
        "Capacity to perform in high-stress situations.",
      ],
    },
  ];
  const offer = [
    "Competitive salary of £60,000/year.",
    "Full-time role - night shifts on a 4 on, 4 off schedule.",
    "Generous annual leave entitlement.",

    "Opportunities for career growth and continuous development.",
    "Base location: Luton.",
  ];
  const applicationDetails = [
    "Start Date: ASAP",
    "Reference ID: MES-PARAMEDIC-2024",
    "Work Location: In person",
  ];

  return (
    <>
      <Helmet>
        <title>We're hiring: Full-time Paramedics</title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/careers/job-post/paramedics-luton"
        />
        <meta
          name="description"
          content="Full-time role - night shifts on a 4 on, 4 off - Frontline NHS contract. Join our team, apply today!"
        />
      </Helmet>
      <div className="overflow-hidden bg-white py-20 px-6 sm:px-12 max-w-7xl mx-auto">
        <div className="justify-start mb-8">
          <Link to="/careers">
            <span className="relative text-lg leading-6 text-gray-600 underline underline-offset-4 ">
              &larr; Return to Careers
            </span>
          </Link>
        </div>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Paramedics - Luton
        </h1>
        <div className="mt-8 space-y-6">
          <div>
            <p className="mt-2 text-m leading-8 text-gray-600">
              📍 Location: Luton, England 💼
            </p>
            <p className="mt-2 text-m leading-8 text-gray-600">
              💼 Position: Paramedic, Full-Time night shifts (4 on, 4 off)
            </p>
            <p className="mt-2 text-m leading-8 text-gray-600">
              💰 Salary: £60,000/year
            </p>
          </div>
          <div className="mt-8 space-y-6">
            <p className="mt-2 leading-8 text-gray-600">
              Medical Emergency Solutions Ltd (MES) is an independent ambulance
              service looking for highly qualified Paramedics to join our team
              for <strong>frontline work under an NHS contract</strong>. This
              role involves responding to emergency calls, delivering
              pre-hospital care, and ensuring safe patient transport to
              healthcare facilities. We are looking for professionals with
              strong clinical skills, composure under pressure, and a
              patient-centred approach.
            </p>
          </div>

          <div className="my-10">
            <h2 className="text-xl font-semibold text-sky-700 mb-5">
              Responsibilities
            </h2>
            {responsibilities.map((responsibility) => (
              <div className="mb-5">
                <h3
                  key={responsibility.name}
                  className="text-l font-semibold text-gray-600"
                >
                  {responsibility.name}
                </h3>
                <ul className="mt-2 space-y-2 text-gray-600 list-disc list-inside">
                  {responsibility.list.map((point) => (
                    <li key={point}>{point}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="my-10">
            <h2 className="text-xl font-semibold text-sky-700 mb-5">
              Qualifications
            </h2>
            {qualifications.map((qualification) => (
              <div className="mb-5">
                <h3
                  key={qualification.name}
                  className="text-l font-semibold text-gray-600"
                >
                  {qualification.name}
                </h3>
                <ul className="mt-2 space-y-2 text-gray-600 list-disc list-inside">
                  {qualification.list.map((point) => (
                    <li key={point}>{point}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="my-10">
            <h2 className="text-xl font-semibold text-sky-700 mb-5">
              What We Offer
            </h2>
            {offer.map((point) => (
              <div className="mb-5">
                <ul className="mt-2 space-y-2 text-gray-600 list-disc list-inside">
                  <li key={point}>{point}</li>
                </ul>
              </div>
            ))}
          </div>
          <div className="my-10">
            <h2 className="text-xl font-semibold text-sky-700 mb-5">
              Application Details
            </h2>
            {applicationDetails.map((point) => (
              <div className="mb-5">
                <ul className="mt-2 space-y-2 text-gray-600 list-disc list-inside">
                  <li key={point}>{point}</li>
                </ul>
              </div>
            ))}
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">Apply now!</h2>
            <p className="mt-2 leading-8 text-gray-600">
              To apply, please email your most recent CV to{" "}
              <a
                href="mailto:recruitment@medicalemergencysolutions.co.uk"
                className="text-sky-700 hover:underline"
              >
                recruitment@medicalemergencysolutions.co.uk
              </a>
              . If you are shortlisted as a potential candidate, a member of our
              team will get in touch with you.
            </p>
            <p className="mt-2 leading-8 text-gray-600 italic">Good luck!</p>
          </div>
        </div>
      </div>
    </>
  );
}
