import { Cloudinary } from "@cloudinary/url-gen";
import { auto } from "@cloudinary/url-gen/actions/resize";
// import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";

const cld = new Cloudinary({ cloud: { cloudName: "dvwwg5ikh" } });

export const getCloudinaryImage = (publicId, width = 1000, height = 1000) => {
  return cld
    .image(publicId)
    .format("auto") // Optimize delivery by resizing and applying auto-format and auto-quality
    .quality("auto")
    .resize(
      auto()
        .width(width)
        .height(height)
        .aspectRatio(16 | 9)
    ); // Transform the image
};
