import { useState } from "react";
import { Link } from "react-router-dom";
import { AdvancedImage } from "@cloudinary/react";
import { getCloudinaryImage } from "../../utils/cloudinaryUtils";

export default function AboutUsHero() {
  const [isLoading, setIsLoading] = useState(true);
  const img = getCloudinaryImage("Add_a_heading_cyxjf0");

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  return (
    <div
      className={`relative isolate overflow-hidden  py-32 sm:py-52 ${
        isLoading ? "bg-sky-950" : ""
      }`}
    >
      {/* <img
        src="/images/about-hero.jpg"
        alt="patient receiving care from paramedics, focus on the equipment monitor"
        className="absolute inset-0 -z-10 h-full w-full object-cover object-center "
        onLoad={handleImageLoaded}
      /> */}
      <AdvancedImage
        cldImg={img}
        className="absolute inset-0 -z-10 h-full w-full object-cover object-center"
        onLoad={handleImageLoaded}
      />
      ;
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-sky-900 to-sky-700 opacity-20"
          style={{
            clipPath:
              "clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-sky-900 to-sky-700 opacity-20"
          style={{
            clipPath:
              "clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div className="absolute top-5 left-5  ">
        <Link to="/">
          <h1 className="text-sm md:text-md leading-6 text-gray-200 underline underline-offset-4 ">
            &larr; Return to Home
          </h1>
        </Link>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col items-center">
        <div className="mx-auto w-full md:w-3/4 lg:mx-0 ">
          <h2 className="text-lg font-bold tracking-tight text-white sm:text-3xl  text-center">
            We are
          </h2>
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl text-center">
            Medical Emergency Solutions
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-200 text-center">
            MES is a <strong>pioneering consultant-lead company</strong>{" "}
            dedicated to delivering comprehensive medical management services,
            ensuring the safety and well-being of our patients.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-200 text-center">
            With a commitment to excellence and a focus on healthcare expertise,
            the company has established itself as a{" "}
            <strong>trusted partner</strong> in the realm of{" "}
            <strong>
              Acute Medical Service Provisions and Event Medical Support.
            </strong>
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <dl className="mt-16 grid grid-cols-1 gap-10 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4 mx-auto">
            <div className="flex flex-col-reverse">
              <dt className="text-base leading-7 text-gray-200">Founded in</dt>
              <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                2019
              </dd>
            </div>
            <div className="flex flex-col-reverse">
              <dt className="text-base leading-7 text-gray-200">
                Collaborators
              </dt>
              <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                60+
              </dd>
            </div>
            <div className="flex flex-col-reverse">
              <dt className="text-base leading-7 text-gray-200">days/year</dt>
              <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                365
              </dd>
            </div>
            <div className="flex flex-col-reverse">
              <dt className="text-base leading-7 text-gray-200">
                Sports events
              </dt>
              <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                200+
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
