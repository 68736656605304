import { Helmet } from "react-helmet";
import SeparatorRight from "../../components/SeparatorRight";
import Highlights from "../../components/highlights";
import CareersCTA from "./CareersCTA";
import CareersHero from "./CareersHero";
import CareerOffers from "./CareersOffers";

export default function Careers() {
  return (
    <div>
      <Helmet>
        <title>
          Explore Your Career Opportunities in Ambulance Services - Luton
        </title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/careers"
        />
        <meta
          name="description"
          content="Discover how you can join our dedicated team as a Paramedic, Emergency Medical Technician, or Associate Ambulance Practitioner for both frontline and event medical cover."
        />
      </Helmet>
      <CareersHero />
      <SeparatorRight />
      <CareerOffers />
      <CareersCTA />
      <Highlights />
    </div>
  );
}
