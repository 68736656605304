import UCCHero from "./UCCHero";
import SeparatorRight from "../../components/SeparatorRight";

import UCCStats from "./UCCStats";
import UCCInfo from "./UCCInfo";
import UCCLocation from "./UCCLocation";
import UCCTestimonial from "./UCCtestimonial";
import { Helmet } from "react-helmet";

export default function UCC() {
  return (
    <>
      <Helmet>
        <title>
          Private Urgent Care Centre in London at the Cromwell Hospital
        </title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/urgent-care-centre"
        />
        <meta
          name="description"
          content="A walk-in urgent care service for patients over 18, dealing with a range of non-emergency injuries and illnesses without the need for appointments or referrals."
        />
      </Helmet>
      <UCCHero />
      <SeparatorRight />
      <UCCStats />
      <UCCInfo />
      <UCCTestimonial />
      <UCCLocation />
    </>
  );
}
