import CTA from "../../components/CTA";
import Clients from "../../components/Clients";

import WorkWithUs from "../../components/WorkWithUs";
import Highlights from "../../components/highlights";
import SeparatorRight from "../../components/SeparatorRight";
import TransferHero from "./TransferHero";
import TransferList from "./TransferList";
import Repatriation from "./Repatriation";
import Compliance from "../../components/Compliance";
import { Helmet } from "react-helmet";

export default function Transfer() {
  return (
    <div>
      <Helmet>
        <title>Private Ambulance Transfer Service, Patient Transport </title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/about"
        />
        <meta
          name="description"
          content="MES provides patient transport to/from GPs and hospitals, high dependency transfers, organ and blood transfers, urgent referrals for admission via A&E, and more."
        />
      </Helmet>
      <TransferHero />
      <SeparatorRight />
      <TransferList />
      <Repatriation />
      <Compliance />
      <Highlights />
      <CTA />
      <Clients />
      <WorkWithUs />
    </div>
  );
}
