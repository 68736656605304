import { Link } from "react-router-dom";
// import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { AdvancedImage } from "@cloudinary/react";
import { getCloudinaryImage } from "../utils/cloudinaryUtils";
export default function People() {
  const imgParamedic1 = getCloudinaryImage("team_post_o2cucf");
  const imgParamedic2 = getCloudinaryImage("Untitled_design_7_ypm1q2");
  const imgParamedic3 = getCloudinaryImage("Untitled_design_8_sb2xqg");
  const imgParamedic4 = getCloudinaryImage("Untitled_design_9_cozhvl");
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none lg:grid lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our people
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-600">
              Our aim is to deliver excellent patient experiences and to be a
              <strong> family-friendly business</strong> with a{" "}
              <strong> welcoming, supportive, and caring atmosphere</strong>.
              This allows us to recruit, retain, and develop high-quality staff
              who are dedicated to providing compassionate care to patients in
              both the NHS and private sectors.
            </p>

            <p className="mt-4 text-lg leading-8 text-gray-600">
              We prioritise the quality of care by ensuring every member of our
              staff is thoroughly{" "}
              <strong>trained and continually supported</strong>. This
              commitment to professional development maximises their skills and
              education, essential for delivering the highest standards of
              patient care.
            </p>
            <div className="mt-8">
              <Link
                to="/careers"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-5 py-3 text-base font-medium text-white hover:bg-teal-600"
              >
                Join our team →
              </Link>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2">
            <AdvancedImage
              cldImg={imgParamedic1}
              className="w-full rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              alt="Ambulance staff entering a rapid response car"
            />
            {/* <img
              className="w-full rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src="/images/staff-11.jpg"
              alt="Ambulance staff posing behind an ambulance"
            /> */}
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 lg:grid-rows-2 lg:gap-8">
          <div className="row-span-2">
            <AdvancedImage
              cldImg={imgParamedic3}
              className="w-full  h-full lg:h-4/5 rounded-lg object-cover shadow-xl ring-1 ring-black ring-opacity-5"
              alt="Paramedics driving an ambulance"
            />
            {/* <img
              className="w-full h-full rounded-lg object-cover shadow-xl ring-1 ring-black ring-opacity-5"
              src="/images/staff-12.jpg"
              alt="Ambulance staff posing behind an ambulance"
            /> */}
          </div>
          <div className="col-span-1 row-span-2">
            <AdvancedImage
              cldImg={imgParamedic4}
              className="hidden md:block w-full h-full lg:h-2/3   rounded-lg object-cover shadow-xl ring-1 ring-black ring-opacity-5"
              alt="Ambulance staff performing checks inside ambulance"
              // sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
            />
            {/* <img
              className="hidden md:block w-full h-auto rounded-lg object-cover shadow-xl ring-1 ring-black ring-opacity-5"
              src="/images/shutterstock_766454269.jpg"
              // srcset="medium-image.jpg 768w, large-image.jpg 1024w"
              sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
              alt="Ambulance staff inside ambulance' driving cabin"
            /> */}
          </div>
          <div className="col-span-1 row-span-1">
            <AdvancedImage
              cldImg={imgParamedic2}
              className="hidden md:block w-70 h-auto rounded-lg object-cover shadow-xl ring-1 ring-black ring-opacity-5 "
              alt="Ambulance staff posing with a rapid response car"
              // sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
            />
            {/* <img
              className="hidden md:block w-64 h-auto rounded-lg object-cover shadow-xl ring-1 ring-black ring-opacity-5 "
              src="/images/staff-13.jpg"
              alt="Ambulance staff inside ambulance' driving cabin"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
