import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function TermsAndCond() {
  return (
    <>
      <Helmet>
        <title>MES Website Terms and Conditions</title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/terms-and-conditions"
        />
        <meta
          name="description"
          content="Welcome to Medical Emergency Solutions. By accessing or using our website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please do not use our website.
"
        />
      </Helmet>
      <div className="overflow-hidden bg-white py-20 px-6 sm:px-12 max-w-7xl mx-auto">
        <div className="justify-start mb-8">
          <Link to="/">
            <span className="relative text-lg leading-6 text-gray-600 underline underline-offset-4 ">
              &larr; Return to Home
            </span>
          </Link>
        </div>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Terms and Conditions
        </h1>
        <div className="mt-8 space-y-6">
          <div>
            <h2 className="text-xl font-semibold text-sky-700">
              1. Introduction
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Welcome to Medical Emergency Solutions. By accessing or using our
              website, you agree to comply with and be bound by these Terms and
              Conditions. If you do not agree with these terms, please do not
              use our website.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">
              2. Use of Website
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              The information and materials on this website are provided for
              general informational purposes only. They do not constitute
              professional advice or create a business, contractual, or
              employment relationship with Medical Emergency Solutions.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">
              3. Intellectual Property
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              All content on this website, including text, images, and code, is
              the property of Medical Emergency Solutions unless otherwise
              stated. You may not reproduce, distribute, or transmit any content
              without our express written consent.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">
              4. Disclaimers and Limitation of Liability
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              This website is provided "as is" without any warranties of any
              kind, either express or implied. Medical Emergency Solutions is
              not liable for any damages arising from your use of the website,
              including but not limited to direct, indirect, incidental, or
              consequential damages.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">
              5. Third-Party Links
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Our website may contain links to third-party sites. We do not
              endorse or take responsibility for the content or practices of
              these sites. Accessing these links is at your own risk.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">
              6. Changes to Terms
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              We may update these Terms and Conditions from time to time. We
              encourage you to review this page periodically to stay informed of
              any changes.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">
              7. Governing Law
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              These Terms and Conditions are governed by and construed in
              accordance with the laws of England and Wales. Any disputes
              arising under or in connection with these Terms will be subject to
              the exclusive jurisdiction of the courts of England and Wales.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">
              8. Contact Information
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              For any questions regarding these Terms and Conditions, please
              contact us at:
              <br />
              Email:{" "}
              <a
                href="mailto:info@medicalemergencysolutions.co.uk"
                className="text-sky-700 hover:underline"
              >
                info@medicalemergencysolutions.co.uk
              </a>
              <br />
              Address: Medical Emergency Solutions,Unit 9, 199 Camford Way,
              Luton LU3 3AN
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
