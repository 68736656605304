import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function OpsManager() {
  const responsibilities = [
    {
      name: "Crew Management",
      list: [
        "Match medical staff to event requirements based on crowd size, event nature, and potential health risks.",
        "Coordinate schedules to ensure adequate staffing, including breaks and emergency procedures.",
        "Strategically position medical facilities and equipment for optimal response times.",
      ],
    },
    {
      name: "Event Coordination",
      list: [
        "Collaborate with organisers to develop tailored medical coverage plans.",
        "Conduct thorough risk assessments and implement contingency plans.",
        "Establish emergency response protocols, including communication, evacuation plans and coordination with local emergency services.",
      ],
    },
    {
      name: "Medical Oversight",
      list: [
        "Supervise medical staff during events, ensuring adherence to protocols and standards.",
        "Coordinate with on-site emergency services, such as ambulance services and first responders, to ensure a seamless response to medical emergencies.",
        "Ensure the timely provision of care for minor injuries and critical cases.",
      ],
    },
    {
      name: "Post-Event Evaluation",
      list: [
        "Conduct reviews of event medical coverage to analyse response effectiveness and identify improvements.",
        "Maintain accurate records of incidents, treatments, and outcomes, for both internal analysis and regulatory compliance.",
      ],
    },
    {
      name: "Compliance",
      list: [
        "Ensure all services comply with all relevant regulations and standards, including Care Quality Commission (CQC) and other regulations.",
        "Maintain updated certifications, licences, and permits.",
        "Ensure that medical staff maintain up-to-date training, meeting the latest medical standards and regulatory requirements.",
      ],
    },
  ];
  const qualifications = [
    {
      name: "The ideal candidate will have:",
      list: [
        "Proven experience in the ambulance service or healthcare sector, preferably in management.*",
        "Proficiency in Microsoft Office (Word, Excel, PowerPoint) for administrative tasks and reporting.",
        "Two to three years of experience in healthcare event management or a related field.",
      ],
    },
    {
      name: "Desirable qualities include:",
      list: [
        "Strong risk assessment and contingency planning skills for medical emergencies.",
        "Resource management expertise for equipment, supplies, and personnel.",
        "A track record of ensuring healthcare regulatory compliance, such as Care Quality Commission (CQC).",
        "Excellent verbal and written communication skills, capable of effectively interacting with clients, staff, and stakeholders.",
      ],
    },
  ];
  const offer = [
    "Competitive salary and benefits package.",
    "Opportunities for professional growth in a dynamic, supportive environment.",
    "The chance to contribute to community safety and well-being.",
  ];
  const applicationDetails = [
    "Start Date: ASAP",
    "Reference ID: MES-OPSMANAGER-2024",
    "Work Location: In person",
  ];

  return (
    <>
      <Helmet>
        <title>We're hiring: Operations and Events Manager</title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/careers/job-post/opsmanager"
        />
        <meta
          name="description"
          content="If you're passionate about healthcare event management and thrive in dynamic environments, we'd love to hear from you! Apply today"
        />
      </Helmet>
      <div className="overflow-hidden bg-white py-20 px-6 sm:px-12 max-w-7xl mx-auto">
        <div className="justify-start mb-8">
          <Link to="/careers">
            <span className="relative text-lg leading-6 text-gray-600 underline underline-offset-4 ">
              &larr; Return to Careers
            </span>
          </Link>
        </div>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Operations and Events Manager
        </h1>
        <div className="mt-8 space-y-6">
          <div>
            <p className="mt-2 text-m leading-8 text-red-600">
              * This job post is now closed *
            </p>
            <p className="mt-2 text-m leading-8 text-gray-600">
              📍 Location: Luton, England 💼
            </p>
            <p className="mt-2 text-m leading-8 text-gray-600">
              💼 Position: Operations and Events Manager
            </p>
            <p className="mt-2 text-m leading-8 text-gray-600">
              💰 Salary: Negotiable (£33,280–£41,600 per annum, depending on
              grade and experience, based on a 40-hour week)
            </p>
          </div>
          <div className="mt-8 space-y-6">
            <p className="mt-2 leading-8 text-gray-600">
              We are looking for an{" "}
              <strong>Operations and Events Manager</strong> to join our team at
              Medical Emergency Solutions (MES), a{" "}
              <strong>consultant-led ambulance company</strong> specialising in{" "}
              <strong>event and frontline medical cover</strong>. With extensive
              experience providing medical services for a wide range of events,
              from music festivals to sporting and corporate gatherings, MES is
              committed to prioritising safety and well-being through meticulous
              planning, expert care, and responsive support.
            </p>
            <p className="mt-2 leading-8 text-gray-600">
              In this pivotal role, you will work{" "}
              <strong>
                closely with paramedics, emergency medical technicians (EMTs),
                emergency care assistants (ECAs), and emergency doctors and
                nurses
              </strong>{" "}
              to ensure the delivery of effective and efficient medical care
              during events, as well as emergency and frontline work. As the
              Operations and Events Manager, you will{" "}
              <strong>attend meetings with event organisers</strong> to discuss
              medical coverage requirements, develop detailed action plans, and{" "}
              <strong>manage the rota</strong> to allocate the right medical
              personnel.
            </p>
            <p className="mt-2  leading-8 text-gray-600">
              You will also be responsible for{" "}
              <strong>ensuring compliance</strong> with healthcare regulatory
              bodies, including the Care Quality Commission (CQC), maintaining
              the highest standards of care at all times.{" "}
              <strong>Strong communication skills are essential</strong>, as the
              role involves collaborating with company directors, event
              organisers, and the medical team to ensure smooth and efficient
              operations. If you're passionate about healthcare event management
              and thrive in dynamic environments, we'd love to hear from you!
            </p>
          </div>

          <div className="my-10">
            <h2 className="text-xl font-semibold text-sky-700 mb-5">
              Responsibilities
            </h2>
            {responsibilities.map((responsibility) => (
              <div className="mb-5">
                <h3
                  key={responsibility.name}
                  className="text-l font-semibold text-gray-600"
                >
                  {responsibility.name}
                </h3>
                <ul className="mt-2 space-y-2 text-gray-600 list-disc list-inside">
                  {responsibility.list.map((point) => (
                    <li key={point}>{point}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="my-10">
            <h2 className="text-xl font-semibold text-sky-700 mb-5">
              Qualifications
            </h2>
            {qualifications.map((qualification) => (
              <div className="mb-5">
                <h3
                  key={qualification.name}
                  className="text-l font-semibold text-gray-600"
                >
                  {qualification.name}
                </h3>
                <ul className="mt-2 space-y-2 text-gray-600 list-disc list-inside">
                  {qualification.list.map((point) => (
                    <li key={point}>{point}</li>
                  ))}
                </ul>
                <p className="mt-5 space-y-2 text-gray-600 italic">
                  * Although experience in non-healthcare events will be
                  considered, candidates with a strong management background and
                  the adaptability to thrive in a healthcare setting are highly
                  preferred.
                </p>
              </div>
            ))}
          </div>

          <div className="my-10">
            <h2 className="text-xl font-semibold text-sky-700 mb-5">
              What We Offer
            </h2>
            {offer.map((point) => (
              <div className="mb-5">
                <ul className="mt-2 space-y-2 text-gray-600 list-disc list-inside">
                  <li key={point}>{point}</li>
                </ul>
              </div>
            ))}
          </div>
          <div className="my-10">
            <h2 className="text-xl font-semibold text-sky-700 mb-5">
              Application Details
            </h2>
            {applicationDetails.map((point) => (
              <div className="mb-5">
                <ul className="mt-2 space-y-2 text-gray-600 list-disc list-inside">
                  <li key={point}>{point}</li>
                </ul>
              </div>
            ))}
          </div>

          <div>
            <h2 className="text-xl font-semibold text-sky-700">Apply now!</h2>
            <p className="mt-2 leading-8 text-gray-600">
              To apply, please email your most recent CV to{" "}
              <a
                href="mailto:recruitment@medicalemergencysolutions.co.uk"
                className="text-sky-700 hover:underline"
              >
                recruitment@medicalemergencysolutions.co.uk
              </a>
              . If you are shortlisted as a potential candidate, a member of our
              team will get in touch with you.
            </p>
            <p className="mt-2 leading-8 text-gray-600 italic">Good luck!</p>
          </div>
        </div>
      </div>
    </>
  );
}
